import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../scss/404.scss"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 className="not_found_title">NOT FOUND</h1>
    <p>This page or content does not exist.</p>
  </Layout>
)

export default NotFoundPage
